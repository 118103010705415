export const CLOUD_PROVIDERS = [{details:['Chinese name: 阿里云',
'Parent company: Alibaba',
'Description: Alibaba Cloud, founded in 2009, is a global leader in cloud computing and artificial intelligence, providing a comprehensive suite of global cloud services to thousands of enterprises, developers, and governments organizations in more than 200 countries and regions. Alibaba offers the most scalable cloud computing and is China’s most significant provider in data Management Services. According to IDC, Alibaba Cloud is solidly positioned as the No.1 cloud computing service provider in China. According to Gartner, Alibaba Cloud ranks 1st among cloud service providers in Asia Pacific and 3rd in the world, outranking Google Cloud.'],
market_share: '38.70%',
revenue: 8165.7,
text: 'Alibaba Cloud (Aliyun)',
website: 'https://www.alibabacloud.com'},
{details:['Chinese name: 腾讯云',
'Parent company: Tencent',
"Description: Founded in 2013, Tencent Cloud provides customers with industry-leading cloud products and services. Tencent Cloud operates 53 availability zones in 26 geographical regions around the world, serving millions of customers from more than 100 countries and providing them with powerful, secure and stable cloud products and services to support the development needs of different businesses. Tencent cloud is widely favored by the gaming and video broadcasting industry, where 70% of gaming companies have chosen Tencent's cloud services."],
market_share: '12.60%',
revenue: 2658.6,
text: 'Tencent Cloud',
website: 'https://intl.cloud.tencent.com'},
{details:['Chinese name: 华为云',
'Parent company: HUAWEI',
"Description: Founded in 2011, HUAWEI Cloud is a leading cloud service provider, bringing HUAWEI's 30-plus years of expertise together in ICT infrastructure products and solutions. HUAWEI Cloud is committed to bringing affordable, effective, and reliable cloud and AI services through technological innovation. By the end of 2019, HUAWEI Cloud has launched 210+ cloud services and 210+ solutions."],
market_share: '10.50%',
revenue: 2215.5,
text: 'HUAWEI Cloud',
website: 'https://www.HUAWEIcloud.com/intl/en-us/'},
{details:['Chinese name: 天翼云',
'Parent company: China Telecom International',
"Description: Established in 2012 and headquartered in Hong Kong, China Telecom International has since built 63 overseas PoP nodes and 40 OTN nodes, with international transmission export bandwidth and transcontinental capacity exceeding 9T, and resources on 33 submarine cables. Based on China Telecom's virtual cloud service infrastructure resource pool and professional service capabilities, China Telecom International provides customers with shared or exclusive virtual cloud service resources (including computing, storage and network) leasing services."],
market_share: '8.00%',
revenue: 1688.0,
text: 'E Cloud',
website: 'https://www.ctclouds.com/#homepage'},
{details:['Chinese name: 亚马逊云',
'Parent company: Amazon',
'Description: Founded in 2006, AWS was the first to offer cloud computing infrastructure as a service and has since been the No.1 cloud provider in the global market. To provide the best experience for customers in China and to comply with China’s legal and regulatory requirements, Amazon Web Services has collaborated with China local partners with proper telecom licenses for delivering cloud services. The service operator and provider for Amazon Web Services China (Beijing) Region based out of Beijing and adjacent areas is Beijing Sinnet Technology Co., Ltd. (Sinnet, 光环新网), and the service operator and provider for Amazon Web Services (Ningxia) Region based out of Ningxia is Ningxia Western Cloud Data Technology Co., Ltd. (NWCD 西云数据).'],
market_share: '6.00%',
revenue: 1266.0,
text: 'AWS China',
website: 'https://www.amazonaws.cn/en/'},
{details:['Chinese name: 金山云',
'Parent company: KINGSOFT',
'Description: Founded in 2012, KINGSOFT Cloud has developed a comprehensive suite of cloud computing services, with solutions for many industries, such as government, finance, AIoT, healthcare, industrial engineering, media, video, game, education, Internet, content services. These solutions integrate with AI, big data, IoT, blockchain, edge computing, and AR/VR technologies.'],
market_share: '3.80%',
revenue: 801.8,
text: 'KINGSOFT Cloud',
website: 'https://en.ksyun.com'},
{details:['Chinese name: 百度云',
'Parent company: Baidu',
'Description: Founded in 2012 and became available for the public in 2015, Baidu Cloud is a comprehensive Cloud service provided by Baidu Inc., offering services and solutions such as cloud storage service, client software, file management, resources sharing, and third party integration etc.. Leveraging its unique, “full-stack” competitive edge in AI, algorithms, open platforms and developer ecosystems, Baidu has delivered a strong execution on its cloud and intelligence integration strategy.'],
market_share: '3.70%',
revenue: 780.7,
text: 'Baidu Cloud',
website: 'https://intl.cloud.baidu.com'},
{details:['Chinese name: 浪潮云',
'Parent company: Inspur',
'Description: Inspur Cloud began operation in 2010, and has since been providing datacenter and cloud computing solutions worldwide. It offers servers, storage systems, artificial intelligence, and HPC solutions as well as tax cloud, set top box and home gateway, and financial terminals. Inspur Cloud also offers software outsourcing services, serving government, enterprise, and industrial customers. Authenticated with the most complete and competent security qualifications, Inspur Cloud now holds the biggest market share among government customers.'],
market_share: '3.10%',
revenue: 654.1,
text: 'Inspur Cloud',
website: 'https://cloud.inspur.com'},
{details:['Chinese name: 京东云',
'Parent company:  JD',
'Description: Founded in 2019, JD Cloud is JD.com’s full-service cloud computing platform and integrated cloud service provider. It houses the world’s leading technologies and a wealth of cloud computing solutions, offering full stack services encompassing IaaS, PaaS and SaaS. It also provides full spectrum services from IDC and cloud computing services to integrated offeringsand services spanning several industries. Covering all 4 Public, Private, Hybrid and Proprietary Cloud scenarios, JD Cloud delivers a comprehensive range of cloud computing services.'],
market_share: '2.30%',
revenue: 485.3,
text: 'JD Cloud',
website: 'https://www.jdcloud.com'},
{details:['Chinese name: Azure云',
'Parent company: Microsoft',
'Description: Established in 2010, Azure accounts for nearly 15% of the global public cloud market and came No.2 in ranking following Amazon Web Service. The Azure cloud platform offers more than 200 products and cloud services designed to help build, run, and manage applications across multiple clouds, on-premises, and at the edge, with the tools and frameworks of your choice. Microsoft Azure operated by 21Vianet (Azure China) is a physically separated instance of cloud services located in China. It\'s independently operated and transacted by Shanghai Blue Cloud Technology Co., Ltd. ("21Vianet"), a wholly owned subsidiary of Beijing 21Vianet Broadband Data Center Co., Ltd..'],
market_share: '2.00%',
revenue: 422.0,
text: 'Microsoft Azure',
website: 'https://www.azure.cn/en-us/'}
].map((it, index) => ({details:[],
   ...it, index: index + 1 }));


